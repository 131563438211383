/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Sushma's Portfolio",
  description:
    "Full Stack Developer",
  og: {
    title: "Sushma Shrestha Portfolio",
    type: "website",
    url: "http://sushma1.com.np/",
  },
};

//Home Page
const greeting = {
  title: "Sushma Shrestha",
  logo_name: "Sushma Shrestha",
  nickname: "Devsus",
  subTitle:
    "Full Stack Developer",
  resumeLink:
    "https://drive.google.com/open?id=1XYpYhLeqCdyx_q6l0bQoC7RgwQjAjXPf",
  portfolio_repository: "https://github.com/Sushma-Shrestha",
  githubProfile: "https://github.com/Sushma-Shrestha",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/Sushma-Shrestha",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "Gmail",
    link: "janukashrestha2033@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Linkedin",
    link: "https://www.linkedin.com/in/sushma-shrestha-67a0a4236/",
    fontAwesomeIcon: "fa-linkedin", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "blue", // Reference https://simpleicons.org/?q=instagram
  },
  // {
  //   name: "Skype",
  //   link: "https://t.me/RainbowSunDev",
  //   fontAwesomeIcon: "fa-skype", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "Blue", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    // {
    //   title: "Data Science & AI",
    //   fileName: "DataScienceImg",
    //   skills: [
    //     "⚡ Developing highly scalable production ready models for various deeplearning and statistical use cases",
    //     "⚡ Experience of working with Computer Vision and NLP projects",
    //     "⚡ Complex quantitative modelling for dynamic forecasting and time series analysis",
    //   ],
    //   softwareSkills: [
    //     {
    //       skillName: "Tensorflow",
    //       fontAwesomeClassname: "logos-tensorflow",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Keras",
    //       fontAwesomeClassname: "simple-icons:keras",
    //       style: {
    //         backgroundColor: "white",
    //         color: "#D00000",
    //       },
    //     },
    //     {
    //       skillName: "PyTorch",
    //       fontAwesomeClassname: "logos-pytorch",
    //       style: {
    //         backgroundColor: "transparent",
    //       },
    //     },
    //     {
    //       skillName: "Python",
    //       fontAwesomeClassname: "ion-logo-python",
    //       style: {
    //         backgroundColor: "transparent",
    //         color: "#3776AB",
    //       },
    //     },
    //   ],
    // },
    {
      title: "Full Stack Development",
      fileName: "ful stack.png",
      skills: [
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter",
        "⚡ Creating application backend in Laravel",
      ],
      softwareSkills: [
        // {
        //   skillName: "HTML5",
        //   fontAwesomeClassname: "simple-icons:html5",
        //   style: {
        //     color: "#E34F26",
        //   },
        // },
        // {
        //   skillName: "CSS3",
        //   fontAwesomeClassname: "fa-css3",
        //   style: {
        //     color: "#1572B6",
        //   },
        // },
        // {
        //   skillName: "Sass",
        //   fontAwesomeClassname: "simple-icons:sass",
        //   style: {
        //     color: "#CC6699",
        //   },
        // },
        // {
        //   skillName: "JavaScript",
        //   fontAwesomeClassname: "simple-icons:javascript",
        //   style: {
        //     backgroundColor: "#000000",
        //     color: "#F7DF1E",
        //   },
        // },
        {
          skillName: "Bootstrap",
          fontAwesomeClassname: "simple-icons:bootstrap",
          style: {
            color: "#6a5acd",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NuxtJS",
          fontAwesomeClassname: "simple-icons:nuxtdotjs",
          style: {
            color: "#00DC82",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        // {
        //   skillName: "NPM",
        //   fontAwesomeClassname: "simple-icons:npm",
        //   style: {
        //     color: "#CB3837",
        //   },
        // },
        // {
        //   skillName: "Yarn",
        //   fontAwesomeClassname: "simple-icons:yarn",
        //   style: {
        //     color: "#2C8EBB",
        //   },
        // },
        // {
        //   skillName: "Gatsby",
        //   fontAwesomeClassname: "simple-icons:gatsby",
        //   style: {
        //     color: "#663399",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#405aff",
        //   },
        // },
        {
          skillName: "Laravel",
          fontAwesomeClassname: "simple-icons:laravel",
          style: {
            color: "red",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "cloud.png",
      skills: [
        "⚡ Experience working on cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
      ],
      softwareSkills: [
        // {
        //   skillName: "GCP",
        //   fontAwesomeClassname: "simple-icons:googlecloud",
        //   style: {
        //     color: "#4285F4",
        //   },
        // },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        // {
        //   skillName: "Azure",
        //   fontAwesomeClassname: "simple-icons:microsoftazure",
        //   style: {
        //     color: "#0089D6",
        //   },
        // },
        // {
        //   skillName: "Firebase",
        //   fontAwesomeClassname: "simple-icons:firebase",
        //   style: {
        //     color: "#FFCA28",
        //   },
        // },
        // {
        //   skillName: "PostgreSQL",
        //   fontAwesomeClassname: "simple-icons:postgresql",
        //   style: {
        //     color: "#336791",
        //   },
        // },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#47A248",
        //   },
        // },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        // {
        //   skillName: "Kubernetes",
        //   fontAwesomeClassname: "simple-icons:kubernetes",
        //   style: {
        //     color: "#326CE5",
        //   },
        // },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "uiux skill.png",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "black",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "brown",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobephotoshop",
          style: {
            color: "darkblue",
          },
        },
        // {
        //   skillName: "Inkscape",
        //   fontAwesomeClassname: "simple-icons:inkscape",
        //   style: {
        //     color: "#000000",
        //   },
        // },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/albert_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@albert391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Asia Pacific University of Technology and Innovation",
      subtitle: "BSc (Hons) IT",
      logo_path: "apulogo.jpg",
      alt_name: "APU logo",
      duration: "2020 - 2023",
      descriptions: [
        "⚡ Graduated as Batch Topper with 3.85 GPA.",
        "⚡ Studied basic software engineering subjects like Cloud, Algorithms, DBMS, OS, etc.",
        "⚡ I was selected for Scholarship which is given to top students in college.",
      ],
      website_link: "https://infomaxcollege.edu.np/",
    },
    {
      title: "SOS Hermann Gmeiner School Pokhara",
      subtitle: "+2 Science",
      logo_path: "soslogo.jpeg",
      alt_name: "SOS logo",
      duration: "2017-2019",
      descriptions: [
        "⚡ One of the reputed school in Nepal.",
        "⚡ Completed +2 Science with 3.63 GPA.",
      ],
      website_link: "https://www.soshgspokhara.edu.np/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Problem Solving Certificate",
      subtitle: "- Harishankaran K",
      logo_path: "hackerrank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/767825485900",
      alt_name: "HackerRank",
      color_code: "#000000"
    },
    {
      title: "Python Certificate",
      subtitle: "- Harishankaran K",
      logo_path: "hackerrank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/40271edcb689",
      alt_name: "HackerRank",
      color_code: "#000000"
    },
    {
      title: "React Certificate",
      subtitle: "- Harishankaran K",
      logo_path: "hackerrank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/40271edcb689",
      alt_name: "HackerRank",
      color_code: "#000000"
    },
    // {
    //   title: "CSS Certificate",
    //   subtitle: "- Harishankaran K",
    //   logo_path: "hackerrank.png",
    //   certificate_link:
    //     "https://www.hackerrank.com/certificates/40271edcb689",
    //   alt_name: "HackerRank",
    //   color_code: "#000000"
    // },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "Solution Developer with more than 2 years of experience in developing software solutions for clients. Proficient in Nuxt.js, and Laravel. Passionate about building scalable software solutions.",
  // description:
  //   "I have worked with startup company as a Full Stack Intern and also as a Junior Full Stack Developer. I have also worked with well established company, Resimator Oy based in Finland, as a Flutter Developer. And I have been freelancing for 2 years.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Solution Developer",
          company: "Mercantile Cloud",
          company_url: "https://mercantilecloud.com.np/",
          logo_path: "mercantile.png",
          duration: "Feb 2024 - Present",
          location: "Siddarthchowk, Pokhara, Nepal",
          description: "Mercantile Cloud Consulting has its roots set as far back as 1985, with its origin tied up with the Mercantile Group. It provides servcies DevSecOps-as-a-Service, Cloud Consulting, and App mordernization. I am working as a Solution Developer here, responsible for developing and maintaining web applications and solutions in Nuxt and Laravel.",
          color: "#0879bf",
        },
        {
          title: "Lecturer/Supervisor",
          company: "Infomax College of IT and Management",
          company_url: "https://infomaxcollege.edu.np/",
          logo_path: "infomx logo.png",
          duration: "Jun 2024 - Present",
          location: "Ranipauwa, Pokhara-11",
          description: "Infomax College of IT & Management is one of the pioneers of IT and Management education in and around Pokhara valley. Provides B.Sc.IT and M.B.A. I am working as a Lecturer/Supervisor here, responsible for teaching and supervising students in their projects.",
          color: "#9b1578",
        },
        {
          title: "Laravel Developer",
          company: "Yes Helping Hand Solutions",
          company_url: "https://yhhits.com/",
          logo_path: "yhh logo.jpg",
          duration: "Aug 2023 - Jan 2024",
          location: "Siddarthchowk, Pokhara, Nepal",
          description: "Yes Helping Hand Solution is the company originated for the need of IT solutions to Yes Helping Hand organization that makes various products of hand-made Pashmina and employs differently abled people. I worked as a Laravel Developer here, responsible for developing and maintaining ecommerce mainly and worked on booking system as a project lead.",
          color: "#0879bf",
        },
        {
          title: "Full Stack Junior Developer",
          company: "Everest Technologies",
          company_url: "https://everesttechnologies.com.np/",
          logo_path: "everest_logo.png",
          duration: "Jun 2022 - Jul 2023",
          location: "Siddarthchowk, Pokhara, Nepal",
          description:
            "Everest Technologies  is a software company that specializes in developing various mobile and " +
            "web applications using technologies such as Flutter, React, Laravel, and Node.js. " +
            "I worked as a full-stack junior developer here, responsible for building, " +
            "deploying and maintaining internal web applications.",
          color: "#0879bf",
        },
        {
          title: "Flutter Developer",
          company: "Resimator",
          company_url: "https://resimator.fi/en",
          logo_path: "resimator_logo.png",
          duration: "Jan 2023 - March 2023",
          location: "Turku, Finland",
          description:
            "Resimator is delivering robust and reliable software solutions to customers in Nordics since 2018. " +
            "Hired as project based Flutter Developer, I was responsible for developing and designing the utility application for task tracking.",
          color: "#9b1578",
        },
        {
          title: "Freelance Full Stack Developer",
          company: "Freelance",
          company_url: "",
          logo_path: "freecopy_logo.png",
          duration: "May 2021 - PRESENT",
          location: "Pokhara, Nepal",
          description:
            "I have worked on multiple projects for clients in Nepal.\n Some works: \n https://gnsh.gov.np, https://starnalma.com",
          color: "#fc1f20",
        },
      ],
    },
    // {
    //   title: "Internships",
    //   experiences: [
    //     {
    //       title: "Data Science Research Intern",
    //       company: "Delhivery Pvt. Ltd.",
    //       company_url: "https://www.delhivery.com/",
    //       logo_path: "delhivery_logo.png",
    //       duration: "May 2019 - Sept 2019",
    //       location: "Gurgaon, Haryana",
    //       description:
    //         "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
    //       color: "#ee3c26",
    //     },
    //     {
    //       title: "Data Science Intern",
    //       company: "Intel Indexer LLC",
    //       company_url:
    //         "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
    //       logo_path: "intel_logo.jpg",
    //       duration: "Nov 2018 - Dec 2018",
    //       location: "Work From Home",
    //       description:
    //         "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
    //       color: "#0071C5",
    //     },
    //   ],
    // },
    // {
    //   title: "Volunteerships",
    //   experiences: [
    //     {
    //       title: "Google Explore ML Facilitator",
    //       company: "Google",
    //       company_url: "https://about.google/",
    //       logo_path: "google_logo.png",
    //       duration: "June 2019 - April 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
    //       color: "#4285F4",
    //     },
    //     {
    //       title: "Microsoft Student Partner",
    //       company: "Microsoft",
    //       company_url: "https://www.microsoft.com/",
    //       logo_path: "microsoft_logo.png",
    //       duration: "Aug 2019 - May 2020",
    //       location: "Hyderabad, Telangana",
    //       description:
    //         "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
    //       color: "#D83B01",
    //     },
    //     {
    //       title: "Mozilla Campus Captain",
    //       company: "Mozilla",
    //       company_url: "https://www.mozilla.org/",
    //       logo_path: "mozilla_logo.png",
    //       duration: "Oct 2019 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
    //       color: "#000000",
    //     },
    //     {
    //       title: "Developer Students Club Member",
    //       company: "DSC IIITDM Kurnool",
    //       company_url:
    //         "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
    //       logo_path: "dsc_logo.png",
    //       duration: "Jan 2018 - May 2020",
    //       location: "Kurnool, Andhra Pradesh",
    //       description:
    //         "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
    //       color: "#0C9D58",
    //     },
    //     {
    //       title: "Developer Program Member",
    //       company: "Github",
    //       company_url: "https://github.com/",
    //       logo_path: "github_logo.png",
    //       duration: "July 2019 - PRESENT",
    //       location: "Work From Home",
    //       description:
    //         "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
    //       color: "#181717",
    //     },
    //   ],
    // },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects are built with Nuxt.js, Flutter, Laravel, and React.js, I like to explore new technologies and develop software solutions and quick hacks. I code, design and cook up a storm.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_sushma.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://wordpress.org/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Bagale Tole, Pokhara, Nepal",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Nucleus/@28.2186233,83.9815105,17z/data=!4m14!1m7!3m6!1s0x39959452298e3af3:0xef01308e817dacc4!2sNucleus!8m2!3d28.2186186!4d83.9840854!16s%2Fg%2F11c7vtgsz4!3m5!1s0x39959452298e3af3:0xef01308e817dacc4!8m2!3d28.2186186!4d83.9840854!16s%2Fg%2F11c7vtgsz4?entry=ttu&g_ep=EgoyMDI0MTIwMi4wIKXMDSoASAFQAw%3D%3D",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+977 9825121142",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
